/*    
 *  _________________________________________
 * / Stop right there, ya dingus. This file  \
 * \ should not be edited.                   /
 *  -----------------------------------------
 *  \
 *   \
 *      __
 *     /  \
 *     |  |
 *     @  @
 *     |  |
 *     || |/
 *     || ||
 *     |\_/|
 *     \___/
 *    
 * This file is automatically generated through https://localise.biz.
 *                                                                         
 * To update this file:
 *
 *  $ npm run update-language
 *
 * Last update:    Tue Jul 30 2024 23:07:15 GMT+0200 (Central European Summer Time)
 * Language keys:  169
 * Language code:  en
 *
 */

const language = {
  "Login_Login": "Log in",
  "Login_Login_SubText": "Log in with your username and password.",
  "Login_RememberMe": "Remember me",
  "Login_Helpdesk_1": "Having trouble logging in?",
  "Login_Helpdesk_2": "Contact the helpdesk.",
  "Login_SavedUsers": "Previously logged in users",
  "Login_SavedUsers_LastLoggedIn": "Last login %{date} at %{time}",
  "Main_Filter": "Filter",
  "Main_Filter_External": "Filter by External ID",
  "Main_Filter_Name": "Filter by name",
  "Main_Filter_Alarm": "Alarm level",
  "Main_Filter_Alarm_Red": "Red (highest priority)",
  "Main_Filter_Alarm_Orange": "Orange (urgent)",
  "Main_Filter_Alarm_Green": "Green (low priority / test)",
  "Main_Filter_Time": {
    "0": "Date range",
    "Start": "Start date",
    "End": "End date"
  },
  "Main_OpenAlarms": "Open alarms",
  "Main_ClosedAlarms": "Acknowledged alarms",
  "Main_ShowMore": "Load more",
  "Main_OpenAlarms_MadeBy": "Made by",
  "Main_OpenAlarms_Today": "Today",
  "Main_OpenAlarms_Company": "Customer",
  "Main_By": {
    "Customer": "By **%{alarmCreator} - %{customer}**",
    "No_Customer": " By **%{alarmCreator}**"
  },
  "TopBar_Title": "For alarm center operators",
  "TopBar_Settings": "Settings",
  "TopBar_Tutorial": "Explanation video",
  "TopBar_Sound_Muted": "Muted",
  "TopBar_Logout_Text": "Are you sure you want to log out?",
  "Main_Filter_Alarm_Test_Plural": "Green: tests",
  "Main_Filter_Alarm_Assistance_Plural": "Orange: provide assistance",
  "Main_Filter_Alarm_Panic_Plural": "Red: provide help",
  "Main_OpenAlarms_Yesterday": "Yesterday",
  "Menu_Linked": "Connected customer",
  "Menu_Linked_Sub": "You have access to multiple customers. Select a customer below to connect.",
  "Menu_LinkedLast": "Recently connected customers",
  "Menu_LinkedLast_Sub": "Click to quick connect",
  "Menu_Language": "Language",
  "Dates": {
    "At": "at",
    "Last": "Last",
    "Yesterday": "Yesterday",
    "Today": "Today",
    "Tomorrow": "Tomorrow",
    "Now": "Now"
  },
  "AlarmDetails_Extern": "External ID",
  "AlarmDetails_Customer": "Company/customer",
  "Address_CurrentPos": "Current position",
  "Address_CurrentPos_Meter_Plural": "meters",
  "Address_CurrentPos_Meter": "meter",
  "Address_Inside_Cap": "Inside",
  "Address_Inside": "is inside at",
  "Address_Outside": "is",
  "Address_AlarmPos": "Alarm position",
  "TopBar_Alarms": "Alarms overview",
  "TopBar_AlarmDetails": "Alarm details",
  "TopBar_Main": "X-Guard Alarm Manager",
  "Helpers_WhoHelp": "Helpers",
  "AlarmMap_Map": "Map",
  "Protocol_Response": "Follow-up procedure",
  "Responder_Customer_Title": "Customer",
  "Responder_QuickResponse_Title": "People nearby",
  "Responder_Group_Customer": "Source of alarm",
  "Responder_Group_RA": "Response area",
  "Responder_Group_PC": "Contacts",
  "Responder_Group_QR": "People nearby",
  "Responder_Group_NQR": "National response",
  "Responder_Group_BasedDistance": "Based on distance",
  "Helpers_Tooltip": "People listed here have confirmed that they are responding to the alarm. They might have received an automatic call notifying them about the alarm. Check the event log for details.",
  "Helpers_None": "Nobody confirmed responding to this alarm.",
  "Error_Validation_DuplicateValue": "Duplicate value has been ignored",
  "Main_Filter_External_Placeholder": "External ID",
  "Main_Filter_Name_Placeholder": "Name",
  "Main_Filter_Time_Start_Placeholder": "Start date and time",
  "Main_Filter_Time_End_Placeholder": "End date and time",
  "Warning_AlarmFilter_Active": "Notice: filters are active",
  "Alarm_Detail_Log_Title": "Event log / chat",
  "Alarm_Detail_Log_Messages": "Messages",
  "Alarm_Detail_Log_AddMessages": "Type a message",
  "Alarm_Detail_Log_AddMessagesDisabled": "Adding messages is unavailable",
  "Alarm_Detail_Log_OpenLog": "Open event log",
  "Alarm_Detail_Log_CloseLog": "Close event log",
  "Alarm_Detail_Log_No_Messages": "No events have been logged",
  "Alarm_Detail_Log_Action_Confirmation_True": "I am going to help with this alarm",
  "Alarm_Detail_Log_Action_Confirmation_False": "I am NOT going to help with this alarm",
  "Alarm_Detail_Log_Action_Call": "Called to %{name}",
  "Alarm_Detail_Log_Action_Notification": "Notification sent to %{name}",
  "Alarm_Detail_Log_Action_Sms": "SMS sent to %{name}",
  "Alarm_Detail_Log_Action_Acknowledge": "Alarm acknowledged",
  "Alarm_Detail_Log_Action_Call_Initiated": "Might have called to / clicked on call icon for %{name}",
  "Alarm_Detail_Log_System_Escalated_AlarmCenter": "Alarm has been escalated to alarm center",
  "Alarm_Detail_Log_Action_AcknowledgeMessage": {
    "app": "I have acknowledged this alarm from the X-Guard Alarm app.",
    "web": "This alarm has been closed through the X-Guard Alarm Manager"
  },
  "Alarm_Detail_Log_Action_System_AcknowledgeMessage": "This alarm has been acknowledged automatically",
  "Alarm_Detail_Log_Action_Manual_Notification": "Alarm has been shared with %{name} (automatic phone call + sms)",
  "Alarm_Detail_Log_LoadingMoreMessagesStatus": "Loading messages",
  "Alarm_Detail_Log_LoadingMessagesStatus": "Loading messages",
  "Alarm_Detail_Log_NewMessagesCallToAction": {
    "one": "A new event log / chat message has been added",
    "other": "Multiple event logs / chat messages have been added"
  },
  "Alarm_Acknowledged": "Alarm has been acknowledged",
  "Menu_Linked_Placeholder": "Select a customer",
  "Menu_Updates": "What's new?",
  "Languages": {
    "English": "English",
    "Dutch": "Nederlands",
    "French": "French"
  },
  "Login": {
    "Placeholders": {
      "Username": "Username",
      "Password": "Password"
    },
    "Failed": "Your login credentials were incorrect",
    "Query_Token": {
      "Pending": "Authenticating...",
      "Success": "You are now logged in",
      "Error": "Failed to authenticate"
    },
    "Message": {
      "TokenExpired": "Your session has expired. Please re-authenticate.",
      "TokenInvalid": "Invalid session, please re-authenticate."
    }
  },
  "Alarm_Detail_Contact": {
    "Push_Message": {
      "Subtitle": "The alarm will be shared through a mobile push notification. ",
      "Title": "Push message"
    },
    "Automatic_Phone_Message": {
      "Subtitle": "The alarm details will be shared through an automatic call and SMS message.",
      "Title": "Automatic phone message"
    },
    "Phone_Call": {
      "Title": "Call",
      "Subtitle": "Call the person directly.",
      "Done": "Done",
      "Instructions": "Dial or click the number below to start calling.\n\n(Call up to 2 times)",
      "Result": {
        "Responding": "Callee is going to help",
        "Not_Reachable": "Callee is NOT going to help",
        "No_Response": "Callee could not be reached",
        "Title": "How did the call go?",
        "Skipped": "Cancel call",
        "Wrong_Number": "Phone number is incorrect"
      },
      "AlarmCreator": {
        "Result": {
          "Responding": "Alarm creator does not need help",
          "Not_Reachable": "Alarm creator needs help",
          "No_Response": "Could not be reached"
        }
      }
    },
    "Sms_Message": {
      "Title": "SMS Message",
      "Subtitle": "The alarm will be shared through a SMS message. ",
      "Output": {
        "Title": "SMS example"
      },
      "Toast": {
        "Error": "Something went wrong sending the SMS message",
        "Sending": "Sending SMS...",
        "Sent": "SMS message has been sent"
      }
    },
    "Email": {
      "Title": "Write an e-mail",
      "Subtitle": {
        "one": "The alarm will be shared through an e-mail message. ",
        "other": "The alarm will be shared through an e-mail message. "
      },
      "Output": {
        "Title": "Message preview"
      },
      "Subject": "X-Guard - An alarm has been shared with you",
      "Toast": {
        "Sending": "Sending e-mail...",
        "Sent": "E-mail sent!",
        "Error": "Error sending mail"
      }
    },
    "Title": "Contact %{name}",
    "Builder_Form": {
      "Add_Data": {
        "Title": "Add alarm data",
        "Fields": {
          "Alarm_Position": "Alarm position",
          "Personal_Information": "Personal information",
          "Include_Contact_Code": "Contract / Contact code",
          "Name": "Name",
          "Current_Position": "Current position"
        }
      },
      "Message": {
        "Title": "Enter a message",
        "Subtitle": "Do you want this person to call you back? If so, include your phone number and call-back instructions.",
        "Placeholder": "Enter your message here...",
        "Choose_Template": "Open a template"
      },
      "Send": "Submit"
    }
  },
  "Alarm_Detail_Acknowledge": {
    "Title": "Active alarm",
    "Subtitle": "Do not acknowledge this alarm until help has been provided and the (distress) situation has been fully resolved. All alarm related communications will be deactivated once the alarm has been acknowledged.",
    "Button_Label": "Acknowledge alarm",
    "Survey": {
      "Title": "Alarm acknowledgement survey",
      "customerHappy": {
        "Title": "Was the alarm creator satisfied with the way this alarm was handled?"
      },
      "Item": {
        "yes": "Yes",
        "no": "No",
        "unknown": "Unknown",
        "notApplicable": "N/A",
        "other": "Other",
        "skipped": "Skip",
        "noAudioConnection": "There was no call",
        "noSoundOnConnection": "There was a call, but nothing could be heard",
        "noHumanResponse": "There was a call, but it was unintelligible",
        "notAccurate": "Low GPS accuracy",
        "notAvailable": "There was no positional information",
        "incorrectAddress": "The address shown was incorrect",
        "notUpToDate": "Location was not up-to-date"
      },
      "audioQualityOk": {
        "Title": "Did the phone call / audio connection work properly?"
      },
      "audioQualityFeedback": {
        "Title": "What went wrong with the phone call / audio connection?"
      },
      "responseOk": {
        "Title": "Was the response well defined?"
      },
      "responseFeedback": {
        "Title": "Please describe what was wrong with the defined response options",
        "Placeholder": "Keep it short, but accurate",
        "Label": "Continue"
      },
      "positionOk": {
        "Title": "Was the location data correct?"
      },
      "positionFeedback": {
        "Title": "Please describe what was wrong with the positional information"
      },
      "comment": {
        "Title": "Acknowledgement notes",
        "Placeholder": "Please summarize the alarm",
        "Label": "Acknowledge alarm"
      }
    },
    "Comment": {
      "customerHappy": "**Customer happy**\n%{message}",
      "audioQualityOk": "**Audio quality OK**\n%{message}",
      "audioQualityFeedback": "**Audio quality feedback**\n%{message}",
      "responseOk": "**Response OK**\n%{message}",
      "responseFeedback": "**Response feedback**\n%{message}",
      "positionFeedback": "**Position feedback**\n%{message}",
      "positionOk": "**Position OK**\n%{message}",
      "comment": "**Comment**\n%{message}"
    },
    "Toast": {
      "Pending": "Acknowledgement in progress...",
      "Success": "Acknowledged alarm successfully",
      "Error": "Failed to acknowledge alarm"
    },
    "Blocked": "This alarm is managed by '%{name}'. Managed alarms can not be acknowledged by you."
  },
  "Menu_Linked_No_Customers_Found": "No customers found",
  "Alarm_Detail_Response_Protocol_Panic": "- DO NOT SPEAK!\n- Connect to the call\n- Listen to the conversation (30 seconds)\n- When help is needed, click 'HELP' below\n- Follow the call order",
  "Alarm_Detail_Response_Protocol_Assistance": "- Connect to the call\n- Speak up immediately and offer assistance: \"Alarm Center, how can I help you?\"\n- When help is needed, click 'HELP' below\n- Follow the call order below",
  "Alarm_Detail_Response_Protocol_Test": "An alarm with the green alarm level has been made. Most of the time, no action is required.\nCheck the alarm name on the left to check on the nature of this alarm and decide if a follow-up is needed.",
  "Alarm_Detail_Response_Procedure": {
    "Priority": {
      "Verification": "Verification",
      "Help": "HELP"
    }
  },
  "Alarm_Detail_Response": {
    "Contact": {
      "Label": "Contact",
      "Call": {
        "Label": "Click to call"
      }
    },
    "Section": {
      "AlarmCreator": {
        "Heading": "Alarm Creator"
      }
    },
    "Type": {
      "ACR_QR": {
        "Title": "People nearby",
        "Subtitle": "Based on distance"
      },
      "ACR_NQR": {
        "Title": "Response partners",
        "Subtitle": "Based on distance"
      },
      "ACR_RA": {
        "Title": "Response area",
        "Subtitle": "Area code %{code}"
      },
      "ACR_PD": {
        "Title": "Police"
      },
      "ACR_PC": {
        "Title": "Personal contacts"
      }
    },
    "Missing": "Responder list has been hidden",
    "Partly_Missing": "Some response details are hidden or missing."
  },
  "Alarm_Detail_Map": {
    "Marker": {
      "Current_Position": {
        "Label": "Current position"
      },
      "Alarm_Position": {
        "Label": "Alarm position"
      }
    },
    "AutoZoom": "Auto Zoom",
    "ImageOverlays": "Building / terrain plans"
  },
  "EnvBanner": {
    "localhost": "Connected to localhost",
    "staging": "Connected to test environment"
  },
  "Alarm_Overview_List": {
    "Closed_Alarms": {
      "No_Results": {
        "With_Filters": "There are no acknowledged alarms matching your filters",
        "No_Filters": "There are no acknowledged alarms"
      }
    },
    "Open_Alarms": {
      "No_Results": {
        "With_Filters": "There are no open alarms matching your filter",
        "No_Filters": "There are no open alarms"
      }
    },
    "Filtered": {
      "Count_Visible": "%{filteredCount} out of %{unfilteredCount} alarms are visible",
      "Remove_Filters": "Remove filters"
    }
  },
  "Footer_TermsAndConditions": "Terms",
  "Footer_Disclaimer": "Disclaimer",
  "Footer_Privacy": "Privacy",
  "AppVersion": "X-Guard Alarm Manager V%{semVer}",
  "Footer_Status": "Status",
  "Permission": {
    "Notification": {
      "Not_Granted": "You have denied receiving browser notifications."
    }
  },
  "Notification": {
    "New_Alarm": {
      "Panic": "New alarm (red)",
      "Assistance": "New alarm (orange)",
      "Test": "New alarm (green)"
    },
    "Multiple_New_Alarms": "There are multiple open alarms"
  },
  "Alarm_Detail_DCC": {
    "Disconnected": {
      "Title": "Call in progress",
      "Tooltip": "Enter this code on your phone to connect.",
      "Mute_On_Join": "Mute my line when joining"
    },
    "Connected": {
      "Title": "You are connected to the call",
      "Incoming_Call_From": "Incoming call from",
      "Connected_To": "Connected parties",
      "Microphone": {
        "Label": "Your microphone is",
        "Muted": "Muted",
        "Enabled": "Enabled"
      },
      "Self": "You",
      "Alarm_Maker": "Alarm creator"
    },
    "Not_Created": "There is no active call, please continue with the protocol below. Do not mention this to the alarm creator.",
    "Destroyed": "The call has ended.\nProceed with the alarm procedure below.",
    "Recording": {
      "Title": "Recording available: you might have missed some information!",
      "Instruction": "We start recording immediately after an alarm button is pressed. \n\nIf you are not sure yet if help is needed, listen to this audio fragment and judge whether help is needed or not. \n\nClick on the 'Help' button below if you heard help is needed."
    }
  },
  "Alarm_Detail_Log_Action_Call_Result_Responding": "%{name} is going to help",
  "Alarm_Detail_Log_Action_Call_Result_Not_Reachable": "%{name} was unable to help at this time",
  "Alarm_Detail_Log_Action_Call_Result_No_Response": "%{name} did not respond to the call",
  "Alarm_Detail_Log_Action_Call_Result_Cancelled": "Cancelled the call to %{name}",
  "Unsupported_Device_Popup": {
    "Title": {
      "Mobile_Device": "Device not supported",
      "Resolution": "Screen resolution not supported"
    },
    "Body": {
      "Mobile_Device": "This website is not made for mobile devices. Please open the Alarm Manager on a desktop browser.",
      "Resolution": "Your browser resolution is currently not supported, issues may occur."
    },
    "Continue": "Continue"
  },
  "Alarm_Detail_Log_Action_Email": "Email sent to %{name}",
  "Alarm_Detail_Log_Action_Protocol_Help": "Switched to 'HELP' protocol. ",
  "Alarm_Detail_Log_Action_Protocol_Verification": "Switched to 'VERIFICATION' protocol",
  "Address_Accuracy": "%{accuracy} accuracy",
  "Address_Unknown": "Unknown location",
  "Main_HiddenAlarms": "Remove search filter to show new alarms.",
  "Error_Validation_NotAValidNumber": "Not a valid number",
  "Alarm": {
    "Date_Created": "Alarm date"
  },
  "Address_View": "View address on Google Maps",
  "Alarm_Detail_Chat": {
    "AlarmCreator": "Alarm Creator"
  },
  "Alarm_Detail_OldAlarmManager": {
    "Title1": "This alarm is also visible through the legacy Alarm Manager.",
    "Title2": "Click to open alarm."
  },
  "Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Responding": "%{name} does not need help",
  "Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Not_Reachable": "%{name} needs help",
  "Distance_Label": {
    "Meters": "%{distance} meter",
    "KiloMeters": "%{distance} km."
  },
  "Alarm_Detail_Log_Action_Alarm_Call_Initiated": "Alarm call initiated successfully (waiting for others to join)",
  "Alarm_Detail_Log_Action_Alarm_Call_Join": "Connected to the alarm call",
  "Alarm_Detail_Log_Action_Alarm_Call_Leave": "Disconnected from the alarm call",
  "Alarm_Detail_Log_Action_Alarm_Call_Start": "Alarm call started",
  "Alarm_Detail_Log_Action_Alarm_Call_Ended": "Alarm call ended",
  "Alarm_Detail_Log_Action_Group_Call_Join": "Connected to responder group call",
  "Alarm_Detail_Log_Action_Group_Call_Leave": "Disconnected from responder group call",
  "DateTimePickerField": {
    "granularity": {
      "days": "Date",
      "hours": "Time"
    },
    "AmPm": {
      "am": "AM",
      "pm": "PM"
    }
  },
  "Report_Code": "Report code: %{code}",
  "Alarm_Detail_Helpers": {
    "Missing": "Helpers list has been hidden"
  },
  "Login_SavedUsers_NeverLoggedIn": "Never logged in before",
  "Login_SavedUsers_ConfirmRemoveToken": "Are you sure you want to remove this session?",
  "Menu_OldAlarmManager": "Click here for the legacy Alarm Manager",
  "Alarm_Detail_Log_NewMessagesNotification": {
    "one": "A new message was added",
    "other": "%{count} new messages were added"
  },
  "AlarmDetails_Description": "Extra information",
  "Alarm_Detail_Log_Action_Call_Result_Wrong_Number": "%{name}'s phone number is incorrect",
  "ErrorDisplay": {
    "Title": {
      "Error": "An error was encountered",
      "Retrying": "An error was encountered, retrying"
    }
  },
  "Alarm_Overview_XgacAlarmCount": "Open alarms - Click here to open",
  "Alarm_Overview_XgacAlarmCount_Sub": "Immediate attention required",
  "Alarm_Detail_Log_Action_Alarm_Merged": "The alarm button was pressed again by the alarm creator.\n\nThe new alarm was merged into this alarm for clarity.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Red": "Alarm level has been escalated from green to red.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Orange": "Alarm level has been escalated from green to orange.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Orange_Red": "Alarm level has been escalated from orange to red.",
  "Alarm_Detail_Log_Action_Alarm_Republished": "Restarting alarm escalation policies",
  "Alarm_Detail_Merge_History": {
    "counter": {
      "subText": "Alarms combined"
    },
    "title": "This alarm has been triggered %{count} times",
    "showAllAlarms": {
      "show": {
        "other": "Display %{count} more alarms",
        "one": "Display 1 more alarm"
      },
      "hide": {
        "other": "Hide %{count} alarms",
        "one": "Hide 1 alarm"
      }
    }
  },
  "Helpers_Anonymous": "Anonymous",
  "Login_SavedUsers_TokenExpired": "Too much time elapsed since last activity, or user was deleted. Please try to login again",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Failed": "Failed to cancel responder %{name}",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Requested": "Requested to cancel responder %{name}",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Accepted": "Responder %{name} has accepted",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Invited": "Responder %{name} invited",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Started": "Responder %{name} started",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_OnLocation": "Responder %{name} has arrived on location",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Finished": "Responder %{name} finished",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Error": "Responder %{name} errored",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancelled": "Responder %{name} cancelled",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Rejected": "Responder %{name} rejected"
} as const

export default language