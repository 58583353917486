import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { getDistance } from 'geolib';
import { fetchStaticResponse } from '../../../../../features/details/response/static';
import { childController, useCustomerContext, withPollingHelper } from '../../../../../lib';
import { alarmCreatorSelector, alarmIdSelector } from '../../../../../features/details';
import { ackedSelector } from '../../../../../features/details/ack/exports';

export const useResponseRefetch = (): void => {

  const dispatch = useDispatch();
  const alarmId = useSelector(alarmIdSelector);
  const coordinates = useSelector(alarmCreatorSelector)?.position?.coordinates ?? null;
  const [previousCoordinates, setPreviousCoordinates] = useState<[number, number]>(null);
  const acked = useSelector(ackedSelector);
  const { signal } = useCustomerContext();

  const { controller, refetch } = useMemo(() => {

    const refetchController = childController(signal);

    if (acked) {

      return { controller: refetchController, refetch: () => {} };

    }

    return {
      controller: refetchController,
      refetch: withPollingHelper(refetchController.signal, 1000 * 15, () => {

        dispatch(fetchStaticResponse({
          alarmId,
          controller: childController(signal),
        }));

        dispatch(fetchStaticResponse({
          alarmId,
          controller: childController(signal),
        }));

      }),
    };

  }, [alarmId, signal]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    return () => controller.abort();

  }, [controller]);

  useEffect(() => {

    // Refetch if the coordinates are 10 meters away from the previous coordinates
    if (coordinates && (!previousCoordinates || getDistance(coordinates, previousCoordinates) > 10)) {

      setPreviousCoordinates(coordinates);
      refetch();

    }

  }, [coordinates, refetch]); // eslint-disable-line react-hooks/exhaustive-deps

};
