import colors from 'style/colors.module.scss';

import { useMemo } from 'react';
import { LogableActions, AnyDisplayableMessage, MessageSide } from 'types';
import { FormatCalendar, useLocale } from 'lib';
import {
  mdiAccountQuestion,
  mdiAlert,
  mdiBullhorn,
  mdiClose,
  mdiEmail,
  mdiEmoticonHappyOutline,
  mdiEmoticonSadOutline,
  mdiMessageText,
  mdiPhone,
  mdiPhoneCheck,
  mdiPhoneMissed,
  mdiPhoneRemove,
  mdiThumbDown,
  mdiThumbUp,
  mdiRestartAlert,
  mdiArrowUpBold,
  mdiCallMerge,
  mdiVanUtility,
} from '@mdi/js';
import { TranslationFunc, TranslationKey } from 'config/i18n';
import { useSelector } from 'react-redux';
import { alarmCreatorSelector } from 'features/details';
import { getSurveyMessageBody } from 'components/pages/authenticated/details/getSurveyMessageBody';
import { AlarmEventType, AlarmSeverity } from '@x-guard/xgac-types/xgac';
import { logger } from 'classes/logger';

export type PresentationValues = {
  isAlarmCreator: boolean;
  messageText: string;
  messageTitle: string;
  messageHeader: string;
  displayTime: boolean;
  messageIcon?: string;
  backgroundColor?: string;
  textColor?: string;
  dateColor?: string;
  side: MessageSide;
  smallCalendar: FormatCalendar;
  largeCalendar: FormatCalendar;
};

const callTitleRegExp = /^\[(.*)]\n/;

/**
 * Gets color, icon and text assets for a given message
 */
const getPresentationValues = (
  t: TranslationFunc,
  message: AnyDisplayableMessage,
  side: MessageSide,
  alarmCreatorUserId: string | undefined,
  alarmCreatorAssetId: string,
): PresentationValues => {

  let messageSide: MessageSide = side;
  let messageText: string = message.text;
  let displayTime = true;
  let messageTitle: string = message.user.name;
  let messageHeader: string = null;
  let messageIcon: string = null;
  let backgroundColor: string = side === 'left' ? colors.success : colors.primary;
  let textColor: string = colors.text;
  let dateColor: string = colors.textSemiTransparent;

  const isAlarmCreator = alarmCreatorUserId === message.user._id;

  if (message.type === 'system') {

    if (message.action !== 'ACTION_CHAT' || !message.meta?.prefixDisplayName) {

      backgroundColor = colors.infoColor;

    }

    // Use translation if provided, otherwise try to parse out the title from message
    if (message.meta.translation) {

      messageText = t(message.meta.translation as TranslationKey, {
        defaultValue: messageText,
        ...message.meta,
      });

    } else {

      const match = message.text.match(callTitleRegExp);

      if (match) {

        messageText = message.text.replace(callTitleRegExp, '').trim();
        messageSide = 'left';
        backgroundColor = colors.success;
        messageTitle = match[1];

      }

    }

  }

  if ((message.type === 'user' && message.action === 'ACTION_CHAT')
    || (message.type === 'action' && message.action === 'ACTION_ALARM_ACKNOWLEDGE')) {

    backgroundColor = colors.background;
    textColor = colors.textDark;
    dateColor = colors.textMedium;

  }

  if (message.type === AlarmEventType.Action) {

    const targetAssetId = (message as LogableActions).meta?.targetAssetId;
    const targetIsAlarmCreator = targetAssetId && targetAssetId === alarmCreatorAssetId;

    switch (message.action) {

    case 'ACTION_CALL':
      messageIcon = mdiPhone;
      messageText = t('Alarm_Detail_Log_Action_Call', message.meta);
      break;
    case 'ACTION_CALL_INITIATED':
      messageIcon = mdiPhone;
      messageText = t('Alarm_Detail_Log_Action_Call_Initiated', message.meta);
      break;
    case 'ACTION_CALL_RESULT_RESPONDING':
      messageIcon = targetIsAlarmCreator ? mdiEmoticonHappyOutline : mdiPhoneCheck;
      messageText = targetIsAlarmCreator
        ? t('Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Responding', message.meta)
        : t('Alarm_Detail_Log_Action_Call_Result_Responding', message.meta);
      break;
    case 'ACTION_CALL_RESULT_NOT_REACHABLE':
      messageIcon = targetIsAlarmCreator ? mdiEmoticonSadOutline : mdiPhoneRemove;
      messageText = targetIsAlarmCreator
        ? t('Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Not_Reachable', message.meta)
        : t('Alarm_Detail_Log_Action_Call_Result_Not_Reachable', message.meta);
      break;
    case 'ACTION_CALL_RESULT_NO_RESPONSE':
      messageIcon = mdiPhoneMissed;
      messageText = t('Alarm_Detail_Log_Action_Call_Result_No_Response', message.meta);
      break;
    case 'ACTION_CALL_RESULT_CANCELLED':
      messageIcon = mdiClose;
      messageText = t('Alarm_Detail_Log_Action_Call_Result_Cancelled', message.meta);
      break;
    case 'ACTION_NOTIFICATION':
      messageIcon = mdiBullhorn;
      messageText = t('Alarm_Detail_Log_Action_Notification', message.meta);
      break;
    case 'ACTION_SMS':
      messageIcon = mdiMessageText;
      messageText = t('Alarm_Detail_Log_Action_Sms', message.meta);
      break;
    case 'ACTION_EMAIL':
      messageIcon = mdiEmail;
      messageText = t('Alarm_Detail_Log_Action_Email', message.meta);
      break;
    case 'ACTION_PROTOCOL_HELP':
      messageIcon = mdiAlert;
      messageText = t('Alarm_Detail_Log_Action_Protocol_Help', message.meta);
      break;
    case 'ACTION_PROTOCOL_VERIFICATION':
      messageIcon = mdiAccountQuestion;
      messageText = t('Alarm_Detail_Log_Action_Protocol_Verification', message.meta);
      break;
    case 'ACTION_RESPONSE_CONFIRMATION':
      [messageText, messageIcon] = message.meta.value
        ? [t('Alarm_Detail_Log_Action_Confirmation_True'), mdiThumbUp]
        : [t('Alarm_Detail_Log_Action_Confirmation_False'), mdiThumbDown];
      break;
    case 'ACTION_ALARM_ACKNOWLEDGE':
      displayTime = false;
      messageHeader = t('Alarm_Detail_Log_Action_Acknowledge');
      messageText = message.meta?.survey
        ? getSurveyMessageBody(message.meta.survey, t)
        : message.meta?.comment ?? message.text;
      break;
    case 'ACTION_MANUAL_NOTIFY':
      messageIcon = mdiBullhorn;
      messageText = t('Alarm_Detail_Log_Action_Manual_Notification', message.meta);
      break;
    case 'ACTION_ALARM_CALL_INITIATED':
      messageIcon = mdiPhone;
      messageText = t('Alarm_Detail_Log_Action_Alarm_Call_Initiated');
      break;
    case 'ACTION_GROUP_CALL_INITIATED':
      break;
    case 'ACTION_GROUP_CALL_JOIN':
      messageText = t('Alarm_Detail_Log_Action_Group_Call_Join');
      break;
    case 'ACTION_GROUP_CALL_LEAVE':
      messageText = t('Alarm_Detail_Log_Action_Group_Call_Leave');
      break;
    case 'ACTION_ALARM_CALL_JOIN':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Call_Join');
      break;
    case 'ACTION_ALARM_CALL_LEAVE':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Call_Leave');
      break;
    case 'ACTION_ALARM_REPUBLISHED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Republished');
      messageIcon = mdiRestartAlert;
      break;
    case 'ACTION_ALARM_LEVEL_INCREASED':

      // eslint-disable-next-line no-case-declarations
      const isFromTo = (from: keyof typeof AlarmSeverity, to: keyof typeof AlarmSeverity, key: TranslationKey) => {

        if (message.meta.from === AlarmSeverity[from] && message.meta.to === AlarmSeverity[to]) {

          messageText = t(key);

        }

      };

      isFromTo('Green', 'Orange', 'Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Orange');
      isFromTo('Orange', 'Red', 'Alarm_Detail_Log_Action_Alarm_Level_Escalated_Orange_Red');
      isFromTo('Green', 'Red', 'Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Red');
      messageIcon = mdiArrowUpBold;
      break;
    case 'ACTION_ALARM_MERGED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Merged', message.meta);
      messageIcon = mdiCallMerge;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_CANCEL_FAILED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Failed', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_CANCEL_REQUESTED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Requested', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_INVITED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Invited', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_ACCEPTED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Accepted', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_REJECTED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Rejected', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_STARTED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Started', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_ONLOCATION':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_OnLocation', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_FINISHED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Finished', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_ERROR':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Error', message.meta);
      messageIcon = mdiVanUtility;
      break;
    case 'ACTION_ALARM_PROFESSIONAL_RESPONDER_CANCELED':
      messageText = t('Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancelled', message.meta);
      messageIcon = mdiVanUtility;
      break;
    default:
      logger.error('Unknown action type', { message });

    }

  }

  return {
    messageText,
    messageHeader,
    messageIcon,
    messageTitle,
    displayTime,
    backgroundColor,
    textColor,
    dateColor,
    side: messageSide,
    isAlarmCreator,
    smallCalendar: {
      sameElse: 'HH:mm:ss',
    },
    largeCalendar: {
      lastDay: `[${t('Dates.Yesterday')}]`,
      sameDay: `[${t('Dates.Today')}]`,
      nextDay: `[${t('Dates.Tomorrow')}]`,
      lastWeek: `[${t('Dates.Last')}] dddd`,
      nextWeek: 'dddd',
      sameElse: 'DD/MM/YYYY HH:mm:ss',
    },
  };

};

/**
 * Hook used to calculate some values relating to the presentation of a message.
 * Will trigger a rerender when the global locale changes
 */
export const usePresentationValues = (message: AnyDisplayableMessage, side: MessageSide): PresentationValues => {

  // Hook into the locale, to trigger a rerender when the locale changes
  const { t } = useLocale();
  const alarmCreator = useSelector(alarmCreatorSelector);
  const alarmCreatorAssetId = alarmCreator._id;
  const alarmCreatorUserId = alarmCreator.user?._id;

  // Memoize presentation values
  return useMemo(() => {

    return getPresentationValues(t, { ...message }, side, alarmCreatorUserId, alarmCreatorAssetId);

  }, [message, side, t, alarmCreatorAssetId, alarmCreatorUserId]);

};
