import { alarmIdSelector, fetchAlarmDetails } from 'features/details';
import { childController, useWebSocketContext } from 'lib';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connectedCustomerSignalSelector } from 'features/identity';

export const useFetchAlarmDetails = (alarmId: string | null) => {

  const dispatch = useDispatch();
  const previousAlarmId = useSelector(alarmIdSelector);
  const customerSignal = useSelector(connectedCustomerSignalSelector);
  const { retryIndex } = useWebSocketContext();

  useEffect(() => {

    // Request new alarm details if previous alarm id is different from the current one
    if (alarmId && previousAlarmId !== alarmId) {

      // Dispatch
      dispatch(fetchAlarmDetails({
        alarmId,
        controller: childController(customerSignal),
      }));

    }

  }, [dispatch, previousAlarmId, alarmId, customerSignal, retryIndex]);

};
