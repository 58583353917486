/*    
 *  _________________________________________
 * / Stop right there, ya dingus. This file  \
 * \ should not be edited.                   /
 *  -----------------------------------------
 *  \
 *   \
 *      __
 *     /  \
 *     |  |
 *     @  @
 *     |  |
 *     || |/
 *     || ||
 *     |\_/|
 *     \___/
 *    
 * This file is automatically generated through https://localise.biz.
 *                                                                         
 * To update this file:
 *
 *  $ npm run update-language
 *
 * Last update:    Tue Jul 30 2024 23:07:15 GMT+0200 (Central European Summer Time)
 * Language keys:  169
 * Language code:  fr
 *
 */

const language = {
  "Login_Login": "Se connecter",
  "Login_Login_SubText": "Connectez-vous à l'aide de votre nom d'utilisateur et de votre mode de passe.",
  "Login_RememberMe": "Se souvenir de moi",
  "Login_Helpdesk_1": "Vous avez des problèmes pour vous connecter ?",
  "Login_Helpdesk_2": "Contacter le helpdesk.",
  "Login_SavedUsers": "Utilisateurs qui se sont connectés précédemment",
  "Login_SavedUsers_LastLoggedIn": "Dernière connexion %{date} à %{time}",
  "Main_Filter": "Filtre",
  "Main_Filter_External": "Filtrer sur base de l'ID externe",
  "Main_Filter_Name": "Filtrer sur base du nom",
  "Main_Filter_Alarm": "Niveau d'alarme",
  "Main_Filter_Alarm_Red": "Rouge (priorité maximale)",
  "Main_Filter_Alarm_Orange": "Orange (urgent)",
  "Main_Filter_Alarm_Green": "Vert (priorité faible / test)",
  "Main_Filter_Time": {
    "0": "Plage de dates",
    "Start": "Date de début",
    "End": "Date de fin"
  },
  "Main_OpenAlarms": "Alarmes ouvertes",
  "Main_ClosedAlarms": "Alarmes acquittées",
  "Main_ShowMore": "Charger plus",
  "Main_OpenAlarms_MadeBy": "Créé par",
  "Main_OpenAlarms_Today": "Aujourd'hui",
  "Main_OpenAlarms_Company": "Client",
  "Main_By": {
    "Customer": "Par **%{alarmCreator} - %{customer}**",
    "No_Customer": " Par **%{alarmCreator}**"
  },
  "TopBar_Title": "Pour les opérateurs de la centrale d'alarme",
  "TopBar_Settings": "Paramètres",
  "TopBar_Tutorial": "Vidéo explicative",
  "TopBar_Sound_Muted": "En mode silencieux",
  "TopBar_Logout_Text": "Êtes-vous sûr de vouloir vous déconnecter ?",
  "Main_Filter_Alarm_Test_Plural": "Vert : tests",
  "Main_Filter_Alarm_Assistance_Plural": "Orange : fournir une assistance",
  "Main_Filter_Alarm_Panic_Plural": "Rouge : fournir de l'aide",
  "Main_OpenAlarms_Yesterday": "Hier",
  "Menu_Linked": "Client connecté",
  "Menu_Linked_Sub": "Vous avez accès à plusieurs clients. Sélectionnez un client ci-dessous pour vous connecter.",
  "Menu_LinkedLast": "Clients qui se sont connectés récemment",
  "Menu_LinkedLast_Sub": "Cliquer pour se connecter rapidement",
  "Menu_Language": "Langue",
  "Dates": {
    "At": "à",
    "Last": "Dernier",
    "Yesterday": "Hier",
    "Today": "Aujourd'hui",
    "Tomorrow": "Demain",
    "Now": "Maintenant"
  },
  "AlarmDetails_Extern": "ID externe",
  "AlarmDetails_Customer": "Société/client",
  "Address_CurrentPos": "Position actuelle",
  "Address_CurrentPos_Meter_Plural": "mètres",
  "Address_CurrentPos_Meter": "mètre",
  "Address_Inside_Cap": "À l'intérieur",
  "Address_Inside": "se trouve à l'intérieur à",
  "Address_Outside": "est",
  "Address_AlarmPos": "Position de l'alarme",
  "TopBar_Alarms": "Aperçu de l'alarme",
  "TopBar_AlarmDetails": "Détails de l'alarme",
  "TopBar_Main": "X-Guard Alarm Manager",
  "Helpers_WhoHelp": "Intervenants",
  "AlarmMap_Map": "Carte",
  "Protocol_Response": "Procédure de suivi",
  "Responder_Customer_Title": "Client",
  "Responder_QuickResponse_Title": "Personnes à proximité",
  "Responder_Group_Customer": "Origine de l'alarme",
  "Responder_Group_RA": "Zone de réaction",
  "Responder_Group_PC": "Contacts",
  "Responder_Group_QR": "Personnes à proximité",
  "Responder_Group_NQR": "Réaction nationale",
  "Responder_Group_BasedDistance": "En fonction de la distance",
  "Helpers_Tooltip": "Les personnes mentionnées ici ont confirmé avoir répondu à l'alarme. Elles sont susceptibles d'avoir reçu un appel automatique les informant de l'alarme. Consultez le journal d'événement pour plus de détails.",
  "Helpers_None": "Personne n'a confirmé sa réaction à cette alarme.",
  "Error_Validation_DuplicateValue": "'Reproduire la valeur' a été ignoré",
  "Main_Filter_External_Placeholder": "ID externe",
  "Main_Filter_Name_Placeholder": "Nom",
  "Main_Filter_Time_Start_Placeholder": "Date et heure de début",
  "Main_Filter_Time_End_Placeholder": "Date et heure de fin",
  "Warning_AlarmFilter_Active": "Attention : des filtres sont activés",
  "Alarm_Detail_Log_Title": "Journal d'événement / chat",
  "Alarm_Detail_Log_Messages": "Message",
  "Alarm_Detail_Log_AddMessages": "Saisir un message",
  "Alarm_Detail_Log_AddMessagesDisabled": "La fonction 'ajouter des messages' n'est pas disponible",
  "Alarm_Detail_Log_OpenLog": "Ouvrir le journal d'événement",
  "Alarm_Detail_Log_CloseLog": "Fermer le journal d'événement",
  "Alarm_Detail_Log_No_Messages": "Aucun événement n'a été enregistré",
  "Alarm_Detail_Log_Action_Confirmation_True": "Je vais intervenir pour cette alarme",
  "Alarm_Detail_Log_Action_Confirmation_False": "Je ne vais PAS intervenir pour cette alarme",
  "Alarm_Detail_Log_Action_Call": "Appel passé à %{name}",
  "Alarm_Detail_Log_Action_Notification": "Notification envoyée à %{name}",
  "Alarm_Detail_Log_Action_Sms": "SMS envoyé à %{name}",
  "Alarm_Detail_Log_Action_Acknowledge": "Alarme acquittée",
  "Alarm_Detail_Log_Action_Call_Initiated": "Pourrait avoir appelé / cliqué sur l'icône d'appel pour %{name}",
  "Alarm_Detail_Log_System_Escalated_AlarmCenter": "L'alarme a été renvoyée à la centrale d'alarme",
  "Alarm_Detail_Log_Action_AcknowledgeMessage": {
    "app": "J'ai acquitté cette alarme de l'application X-Guard Alarm.",
    "web": "Cette alarme a été clôturée à l'aide du X-Guard Alarm Manager"
  },
  "Alarm_Detail_Log_Action_System_AcknowledgeMessage": "Cette alarme a été acquittée automatiquement",
  "Alarm_Detail_Log_Action_Manual_Notification": "L'alarme a été partagée avec %{name} (appel téléphonique automatique + sms)",
  "Alarm_Detail_Log_LoadingMoreMessagesStatus": "Chargement des messages",
  "Alarm_Detail_Log_LoadingMessagesStatus": "Chargement des messages",
  "Alarm_Detail_Log_NewMessagesCallToAction": {
    "one": "Un nouveau journal d'événement / message chat a été ajouté&nbsp;",
    "other": "Plusieurs journaux d'événements / messages Chat ont été ajoutés"
  },
  "Alarm_Acknowledged": "L'alarme a été acquittée",
  "Menu_Linked_Placeholder": "Sélectionner un client",
  "Menu_Updates": "Quoi de neuf ?",
  "Languages": {
    "English": "Anglais",
    "Dutch": "Néerlandais",
    "French": "Français"
  },
  "Login": {
    "Placeholders": {
      "Username": "Nom d'utilisateur",
      "Password": "Mot de passe"
    },
    "Failed": "Vos identifiants de connexion n'étaient pas exacts",
    "Query_Token": {
      "Pending": "En cours d'authentification...",
      "Success": "Vous êtes maintenant connecté",
      "Error": "L'authentification a échoué"
    },
    "Message": {
      "TokenExpired": "Votre session a expiré. Veuillez procéder à une nouvelle authentification.",
      "TokenInvalid": "Session non valable, veuillez procéder à une nouvelle authentification."
    }
  },
  "Alarm_Detail_Contact": {
    "Push_Message": {
      "Subtitle": "L'alarme sera partagée à l'aide d'une notification push mobile.",
      "Title": "Message push"
    },
    "Automatic_Phone_Message": {
      "Subtitle": "Les détails de l'alarme seront partagés à l'aide d'un appel automatique et d'un SMS.",
      "Title": "Message téléphonique automatique"
    },
    "Phone_Call": {
      "Title": "Appeler",
      "Subtitle": "Appeler directement la personne.",
      "Done": "Fait",
      "Instructions": "Composez le numéro ci-dessous ou cliquez sur celui-ci pour démarrer l'appel..\n\n(Jusqu'à deux appels)",
      "Result": {
        "Responding": "La personne appelée va intervenir",
        "Not_Reachable": "La personne appelée n'interviendra PAS",
        "No_Response": "Il n'a pas été possible de joindre la personne appelée",
        "Title": "Comment s'est passé l'appel ?",
        "Skipped": "Annuler l'appel",
        "Wrong_Number": "Le numéro de téléphone est incorrect"
      },
      "AlarmCreator": {
        "Result": {
          "Responding": "La personne qui a créé l'alarme n'a pas besoin d'aide",
          "Not_Reachable": "La personne qui a créé l'alarme a besoin d'aide",
          "No_Response": "N'a pas pu être contacté"
        }
      }
    },
    "Sms_Message": {
      "Title": "SMS",
      "Subtitle": "L'alarme sera partagée à l'aide d'un SMS.",
      "Output": {
        "Title": "Modèle de SMS"
      },
      "Toast": {
        "Error": "Un problème s'est produit durant l'envoi du SMS",
        "Sending": "SMS en cours d'envoi...",
        "Sent": "Un SMS a été envoyé"
      }
    },
    "Email": {
      "Title": "Rédiger un e-mail",
      "Subtitle": {
        "one": "L'alarme sera partagée à l'aide d'un courriel.",
        "other": "L'alarme sera partagée à l'aide d'un courriel."
      },
      "Output": {
        "Title": "Prévisualiser le message"
      },
      "Subject": "X-Guard - Une alarme a été partagée avec vous",
      "Toast": {
        "Sending": "E-mail en cours d'envoi...",
        "Sent": "E-mail envoyé !",
        "Error": "Une erreur s'est produite durant l'envoi du mail"
      }
    },
    "Title": "Contact %{name}",
    "Builder_Form": {
      "Add_Data": {
        "Title": "Ajouter des informations concernant l'alarme",
        "Fields": {
          "Alarm_Position": "Position de l'alarme",
          "Personal_Information": "Informations personnelles",
          "Include_Contact_Code": "Code contrat / contact",
          "Name": "Nom",
          "Current_Position": "Position actuelle"
        }
      },
      "Message": {
        "Title": "Saisir un message",
        "Subtitle": "Voulez-vous que cette personne vous rappelle ? Si oui, indiquez votre numéro de téléphone et les consignes pour l'appel.",
        "Placeholder": "Saisissez votre message ici...",
        "Choose_Template": "Ouvrir un document type"
      },
      "Send": "Soumettre"
    }
  },
  "Alarm_Detail_Acknowledge": {
    "Title": "Alarme active",
    "Subtitle": "N'acquittez pas cette alarme jusqu'à ce qu'une assistance ait été fournie et que la situation (de détresse) ait été complètement résolue. Toutes les communications relatives à cette alarme seront désactivées lorsque celle-ci aura été acquittée.",
    "Button_Label": "Acquitter l'alarme",
    "Survey": {
      "Title": "Vue d'ensemble de l'acquittement de l'alarme",
      "customerHappy": {
        "Title": "La personne qui a créé l'alarme est-elle satisfaite de la manière dont celle-ci a été gérée ?"
      },
      "Item": {
        "yes": "Oui",
        "no": "Non",
        "unknown": "Ignoré",
        "notApplicable": "N/A",
        "other": "Autre",
        "skipped": "Ignorer",
        "noAudioConnection": "Il n'y a pas eu d'appel",
        "noSoundOnConnection": "Il y a eu un appel, mais on n'a rien entendu",
        "noHumanResponse": "Il y a eu un appel, mais il était incompréhensible",
        "notAccurate": "Faible précision GPS",
        "notAvailable": "Il n'y avait aucune information sur la position",
        "incorrectAddress": "L'adresse affichée n'était pas correcte",
        "notUpToDate": "La localisation n'était pas à jour"
      },
      "audioQualityOk": {
        "Title": "L'appel téléphonique / la connexion audio ont-ils fonctionné correctement ?"
      },
      "audioQualityFeedback": {
        "Title": "Que s'est-il passé au niveau de l'appel téléphonique / de la connexion audio ?"
      },
      "responseOk": {
        "Title": "La réaction a-t-elle bien été définie ?"
      },
      "responseFeedback": {
        "Title": "Veuillez décrire le problème survenu au niveau des options de réaction définies",
        "Placeholder": "Soyez bref mais précis",
        "Label": "Continuer"
      },
      "positionOk": {
        "Title": "Les données de localisation étaient-elles exactes ?"
      },
      "positionFeedback": {
        "Title": "Veuillez décrire le problème survenu au niveau des informations concernant la position"
      },
      "comment": {
        "Title": "Notes d'acquittement",
        "Placeholder": "Veuillez résumer l'alarme",
        "Label": "Acquitter l'alarme"
      }
    },
    "Comment": {
      "customerHappy": "**Client satisfait**\n%{message}",
      "audioQualityOk": "**Qualité Audio OK**\n%{message}",
      "audioQualityFeedback": "**Feedback qualité audio**\n%{message}",
      "responseOk": "**Réaction OK**\n%{message}",
      "responseFeedback": "**Feedback réaction**\n%{message}",
      "positionFeedback": "**Feedback position**\n%{message}",
      "positionOk": "**Position OK**\n%{message}",
      "comment": "**Commentaire**\n%{message}"
    },
    "Toast": {
      "Pending": "Acquittement en cours...",
      "Success": "L'alarme a été acquittée avec succès",
      "Error": "L'acquittement de l'alarme a échoué"
    },
    "Blocked": "Cette alarme est gérée par '%{name}'. Vous ne pouvez pas acquitter les alarmes qui sont gérées."
  },
  "Menu_Linked_No_Customers_Found": "Aucun client trouvé",
  "Alarm_Detail_Response_Protocol_Panic": "- NE PARLEZ PAS ! \n- Connectez-vous et écoutez l'appel.\n- Si une aide est nécessaire, cliquez sur 'AIDE' ci-dessous\n- Suivez l'ordre d'appel",
  "Alarm_Detail_Response_Protocol_Assistance": "- Connectez-vous à l'appel\n- Parlez immédiatement et proposez votre aide : \"Centrale d'alarme, que puis-je faire pour vous ?\"\n- Si une aide est nécessaire, cliquez sur 'AIDE' ci-dessous\n- Suivez l'ordre d'appel ci-dessous",
  "Alarm_Detail_Response_Protocol_Test": "Une alarme de niveau vert a été créée. La plupart du temps, aucune intervention n'est requise.\nConsultez le nom de l'alarme à gauche afin de vérifier la nature de cette alarme et de décider de la nécessité d'un suivi.",
  "Alarm_Detail_Response_Procedure": {
    "Priority": {
      "Verification": "Vérification",
      "Help": "AIDE"
    }
  },
  "Alarm_Detail_Response": {
    "Contact": {
      "Label": "Contact",
      "Call": {
        "Label": "Cliquez pour appeler"
      }
    },
    "Section": {
      "AlarmCreator": {
        "Heading": "Personne ayant créé l'alarme"
      }
    },
    "Type": {
      "ACR_QR": {
        "Title": "Personnes à proximité",
        "Subtitle": "En fonction de la distance"
      },
      "ACR_NQR": {
        "Title": "Partenaires d'intervention",
        "Subtitle": "En fonction de la distance"
      },
      "ACR_RA": {
        "Title": "Zone de réaction",
        "Subtitle": "Code zone %{code}"
      },
      "ACR_PD": {
        "Title": "Police"
      },
      "ACR_PC": {
        "Title": "Contacts personnels"
      }
    },
    "Missing": "La liste des intervenants a été masquée",
    "Partly_Missing": "Certains détails de la réponse sont masqués ou manquants."
  },
  "Alarm_Detail_Map": {
    "Marker": {
      "Current_Position": {
        "Label": "Position actuelle"
      },
      "Alarm_Position": {
        "Label": "Position de l'alarme"
      }
    },
    "AutoZoom": "Zoom automatique",
    "ImageOverlays": "Bâtiment / plans du site"
  },
  "EnvBanner": {
    "localhost": "Connecté au localhost",
    "staging": "Connecté à l'environnement test"
  },
  "Alarm_Overview_List": {
    "Closed_Alarms": {
      "No_Results": {
        "With_Filters": "Il n'y a aucune alarme acquittée qui correspond à vos filtres",
        "No_Filters": "Il n'y a aucune alarme acquittée"
      }
    },
    "Open_Alarms": {
      "No_Results": {
        "With_Filters": "Il n'y aucune alarme ouverte qui correspond à votre filtre",
        "No_Filters": "Il n'y a aucune alarme ouverte"
      }
    },
    "Filtered": {
      "Count_Visible": "%{filteredCount} des %{unfilteredCount} alarmes sont visibles",
      "Remove_Filters": "Supprimer les filtres"
    }
  },
  "Footer_TermsAndConditions": "Termes",
  "Footer_Disclaimer": "Avis de non-responsabilité",
  "Footer_Privacy": "Confidentialité",
  "AppVersion": "X-Guard Alarm Manager V%{semVer}",
  "Footer_Status": "Statut",
  "Permission": {
    "Notification": {
      "Not_Granted": "Vous n'avez pas été autorisé à recevoir des notifications navigateur."
    }
  },
  "Notification": {
    "New_Alarm": {
      "Panic": "Nouvelle alarme (rouge)",
      "Assistance": "Nouvelle alarme (orange)",
      "Test": "Nouvelle alarme (vert)"
    },
    "Multiple_New_Alarms": "Il y a plusieurs alarmes ouvertes"
  },
  "Alarm_Detail_DCC": {
    "Disconnected": {
      "Title": "Appel en cours",
      "Tooltip": "Saisissez ce code sur votre téléphone pour vous connecter.",
      "Mute_On_Join": "Mettre ma ligne en silencieux lorsqu'on me contacte"
    },
    "Connected": {
      "Title": "Vous êtes connecté à l'appel",
      "Incoming_Call_From": "Appel entrant de",
      "Connected_To": "Parties connectées",
      "Microphone": {
        "Label": "Votre micro est",
        "Muted": "En mode silencieux",
        "Enabled": "Activé"
      },
      "Self": "Vous",
      "Alarm_Maker": "Personne ayant créé l'alarme"
    },
    "Not_Created": "Il n'y a pas d'appel actif, veuillez poursuivre le protocole ci-dessous. Ne mentionnez pas cette information à la personne qui a créé l'alarme.",
    "Destroyed": "L'appel est terminé. Poursuivez la procédure d'alarme ci-dessous.",
    "Recording": {
      "Title": "Extrait audio disponible",
      "Instruction": "Écoutez les premières secondes de cet extrait afin d'évaluer si une assistance est nécessaire.  "
    }
  },
  "Alarm_Detail_Log_Action_Call_Result_Responding": "%{name} va intervenir",
  "Alarm_Detail_Log_Action_Call_Result_Not_Reachable": "%{name} n'a pas pu intervenir à ce moment-là",
  "Alarm_Detail_Log_Action_Call_Result_No_Response": "%{name} n'a pas répondu à l'appel",
  "Alarm_Detail_Log_Action_Call_Result_Cancelled": "Appel à %{name} annulé",
  "Unsupported_Device_Popup": {
    "Title": {
      "Mobile_Device": "L'appareil n'est pas pris en charge",
      "Resolution": "La résolution de l'écran n'est pas prise en charge"
    },
    "Body": {
      "Mobile_Device": "Ce site internet n'est pas conçu pour les appareils mobiles. Veuillez ouvrir le Gestionnaire d'alarme sur un navigateur de bureau.",
      "Resolution": "La résolution de votre navigateur n'est pas prise en charge pour l'instant. Des problèmes peuvent se produire."
    },
    "Continue": "Continuer"
  },
  "Alarm_Detail_Log_Action_Email": "E-mail envoyé à %{name}",
  "Alarm_Detail_Log_Action_Protocol_Help": "Passé au protocole 'AIDE' ",
  "Alarm_Detail_Log_Action_Protocol_Verification": "Passé au protocole 'VÉRIFICATION'",
  "Address_Accuracy": "%{accuracy} précision",
  "Address_Unknown": "Localisation inconnue",
  "Main_HiddenAlarms": "Supprimer le filtre de recherche pour afficher de nouvelles alarmes.",
  "Error_Validation_NotAValidNumber": "Ce numéro n'est pas valable",
  "Alarm": {
    "Date_Created": "Date de l'alarme"
  },
  "Address_View": "Visualiser l'adresse sur Google Maps",
  "Alarm_Detail_Chat": {
    "AlarmCreator": "Personne ayant créé l'alarme"
  },
  "Alarm_Detail_OldAlarmManager": {
    "Title1": "Cette alarme peut également être visualisée via le Gestionnaire d'alarme.",
    "Title2": "Cliquez pour ouvrir l'alarme."
  },
  "Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Responding": "%{name} n'a pas besoin d'aide",
  "Alarm_Detail_Log_Action_Call_AlarmCreator_Result_Not_Reachable": "%{name} a besoin d'aide",
  "Distance_Label": {
    "Meters": "%{distance} mètres",
    "KiloMeters": "%{distance} km."
  },
  "Alarm_Detail_Log_Action_Alarm_Call_Initiated": "Appel d'urgence initié avec succès (attendre que d'autres personnes se manifestent)",
  "Alarm_Detail_Log_Action_Alarm_Call_Join": "Connecté à l'appel d'urgence",
  "Alarm_Detail_Log_Action_Alarm_Call_Leave": "Déconnecté de l'appel d'urgence",
  "Alarm_Detail_Log_Action_Alarm_Call_Start": "L'appel d'urgence a démarré",
  "Alarm_Detail_Log_Action_Alarm_Call_Ended": "L'appel d'urgence est terminé",
  "Alarm_Detail_Log_Action_Group_Call_Join": "Connecté à l'appel de groupe des intervenants",
  "Alarm_Detail_Log_Action_Group_Call_Leave": "Déconnecté de l'appel de groupe des intervenants",
  "DateTimePickerField": {
    "granularity": {
      "days": "Date",
      "hours": "Heure"
    },
    "AmPm": {
      "am": "AM",
      "pm": "PM"
    }
  },
  "Report_Code": "Code du rapport : %{code}",
  "Alarm_Detail_Helpers": {
    "Missing": "La liste des intervenants a été masquée"
  },
  "Login_SavedUsers_NeverLoggedIn": "Jamais connecté auparavant",
  "Login_SavedUsers_ConfirmRemoveToken": "Voulez-vous vraiment supprimer cet utilisateur ?",
  "Menu_OldAlarmManager": "Cliquez ici pour accéder au Gestionnaire d'alarme",
  "Alarm_Detail_Log_NewMessagesNotification": {
    "one": "Un nouveau message a été ajouté",
    "other": "%{count} nouveaux messages ont été ajoutés"
  },
  "AlarmDetails_Description": "Informations supplémentaires",
  "Alarm_Detail_Log_Action_Call_Result_Wrong_Number": "Le numéro de téléphone de %{name} est incorrect",
  "ErrorDisplay": {
    "Title": {
      "Error": "Une erreur est survenue",
      "Retrying": "Une erreur s'est produite. Nouvelle tentative"
    }
  },
  "Alarm_Overview_XgacAlarmCount": "Ouvrir les alarmes - Cliquez ici pour ouvrir",
  "Alarm_Overview_XgacAlarmCount_Sub": "Attention immédiate requise",
  "Alarm_Detail_Log_Action_Alarm_Merged": "Le bouton d'alarme a été à nouveau enfoncé par le créateur de l'alarme.\n\nLa nouvelle alarme a été fusionnée dans cette alarme pour plus de clarté.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Red": "Le niveau d’alarme est passé du vert au rouge.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Green_Orange": "Le niveau d’alarme est passé du vert à l’orange.",
  "Alarm_Detail_Log_Action_Alarm_Level_Escalated_Orange_Red": "Le niveau d’alarme est passé de l’orange au rouge.",
  "Alarm_Detail_Log_Action_Alarm_Republished": "Redémarrage des politiques de remontée d'alarmes",
  "Alarm_Detail_Merge_History": {
    "counter": {
      "subText": "Alarmes combinées"
    },
    "title": "Cette alarme a été déclenchée %{count} fois",
    "showAllAlarms": {
      "show": {
        "other": "Afficher %{count} alarmes supplémentaires",
        "one": "Afficher 1 alarme supplémentaire"
      },
      "hide": {
        "other": "Masquer %{count} alarmes",
        "one": "Masquer 1 alarme"
      }
    }
  },
  "Helpers_Anonymous": "Anonyme",
  "Login_SavedUsers_TokenExpired": "La session utilisateur a expiré. Veuillez essayer de vous connecter à nouveau",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Failed": "Échec de l'annulation du répondeur",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancel_Requested": "Demandé d'annuler le répondeur %{name}",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Accepted": "Le répondant %{name} a accepté",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Invited": "Répondant %{name} invité",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Started": "Le répondeur %{name} a démarré",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_OnLocation": "Le répondant %{name} est arrivé sur place",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Finished": "Répondeur %{name} a terminé",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Error": "Le répondeur %{name} a commis une erreur",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Cancelled": "Répondant %{name} annulé",
  "Alarm_Detail_Log_Action_Alarm_Professional_Responder_Rejected": "Répondeur %{name} rejeté"
} as const

export default language